// src/components/landing/LandingPage.js
import React from 'react';
import Navbar from '../landing/main/Navbar';
import Hero from '../landing/section/Hero';
import Services from '../landing/section/Services';
import About from '../landing/section/About';
import Contact from '../landing/section/Contact';
import Footer from '../landing/main/Footer';

const LandingPage = () => {
  return (
    <div>
      <Navbar />
      <Hero />
      <Services />
      <About />
      <Footer />
      {/* Add your Hero, Store, About, and other sections here */}
    </div>
  );
};

export default LandingPage;
