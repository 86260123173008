// src/components/landing/Services.js

import React from 'react';
import '../style/about.scss';

const About = () => {
  return (
    <section id="about" className="services">
      {/* First Section */}
      <div id='#about-us' className="services-first-section">
        <div className="services-text">
          <h2>SIGN UP AND GET 15% OFF</h2>
          <div className="line"></div>
          <h2>FIRST ORDER + FREE DELIVERY!</h2>
        </div>
        <div className="services-image">
          <img src='/assets/image8.png' alt="Services" />
        </div>
      </div>

      {/* Second Section */}
      <div className="services-second-section">
        <div className="why-aronia">
          <h3>Why Aronia Treasure?</h3>
          <p>
            Experience the transformative force of Aronia berry, the legendary black chokeberry, nature's powerhouse superfood. Packed with antioxidants, vitamins, and minerals, Aronia promotes immune health, boosts cardiovascular function, and supports optimal digestion. Its potent anti-inflammatory properties also aid in combating oxidative stress and promoting overall wellness.
          </p>
        </div>
        <div className="services-images">
          <div className="service-image">
            <img src='/assets/image6.jpg' alt="Image 1" />
          </div>
          <div className="service-image">
            <img src='/assets/image7.png' alt="Image 2" />
          </div>
        </div>
      </div>

      {/* Third Section - Stats Section */}
      <div className="services-stats">
        <div className="stat-item">
          <h1>100K</h1>
          <h2>SOLD AND STILL COUNTING</h2>
        </div>
        <div className="stat-item">
          <h1>5K</h1>
          <h2>NEW CUSTOMERS LAST YEAR</h2>
        </div>
        <div className="stat-item">
          <h1>35</h1>
          <h2>SATISFIED CUSTOMERS SO FAR</h2>
        </div>
        <div className="stat-item">
          <h1>15Y</h1>
          <h2>EXPERIENCE</h2>
        </div>
      </div>

    </section>
  );
};

export default About;
