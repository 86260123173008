import React from 'react';
import '../style/footer.scss'; // Import the SCSS file
import { FaFacebookF, FaInstagram } from 'react-icons/fa'; // React Icons for social media
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa'; // React Icons for contact info

const Footer = () => {
  return (
      <footer id="contact" className="footer">
        <div className="footer-container">
          {/* Left Section - Logo */}
          <div className="footer-logo">
            <div className="logo-text">
              Aronia Treasure {/* Logo Text on the Left */}
            </div>
          </div>

          {/* Middle Section - Social Media Text and Icons */}
          <div className="footer-middle">
            <h2>
              Stay connected with us on social media to discover the latest updates, recipes, and health tips!
            </h2>
            <div className="social-icons">
              <a href="https://www.facebook.com/aroniatreasure/" target="_blank" rel="noopener noreferrer">
                <FaFacebookF /> {/* Facebook Icon */}
              </a>
              <a href="https://www.instagram.com/aroniatreasure/" target="_blank" rel="noopener noreferrer">
                <FaInstagram /> {/* Instagram Icon */}
              </a>
            </div>
          </div>

          {/* Right Section - Location and Contact Information */}
          <div className="footer-right">
            <div className="footer-location">
              <div className="icon">
                <FaMapMarkerAlt /> {/* Location Icon */}
              </div>
              <span className="footer-location-text">
              ArmedinaUSA PO Box 901, <br />
              Half Moon Bay, CA 94019
            </span>
            </div>
            <div className="footer-phone">
              <div className="icon">
                <FaPhoneAlt /> {/* Phone Icon */}
              </div>
              <span className="footer-phone-number">655 666 2444</span> {/* Replace with actual phone */}
            </div>
            <div className="footer-email">
              <div className="icon">
                <FaEnvelope /> {/* Email Icon */}
              </div>
              <span className="footer-email-address">info@armedinausa.com</span> {/* Replace with actual email */}
            </div>
          </div>
        </div>
      </footer>
  );
};

export default Footer;