import React, { useState } from 'react';
import '../style/navbar.scss';
import { FaBars, FaTimes } from 'react-icons/fa'; // Import icons for menu toggling

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleScrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
        setIsMenuOpen(false); // Close menu after clicking a link
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <nav className="navbar">
            <div className="navbar-logo">ARONIA TREASURE</div>
            <div className="menu-icon" onClick={toggleMenu}>
                {isMenuOpen ? <FaTimes /> : <FaBars />}
            </div>
            <ul className={`navbar-links ${isMenuOpen ? 'open' : ''}`}>
                <li onClick={() => handleScrollToSection('home')}>Home</li>
                <li onClick={() => handleScrollToSection('services')}>Services</li>
                <li onClick={() => handleScrollToSection('about')}>About Us</li>
                <li onClick={() => handleScrollToSection('contact')}>Contact Us</li>
                <li className="navbar-button"
                    onClick={() => window.location.href = 'https://www.armedinausa.com/'}>Order Now
                </li>
            </ul>
        </nav>
    );
};

export default Navbar;