// src/components/landing/section/Hero.js
import React from 'react';
import '../style/hero.scss';

const Hero = () => {
  return (
    <section id="home" className="hero">
      <div id="#hero" className="hero-content">
        <h1>ARONIA TREASURE</h1>
        <h1>RECOVERY. ENERGY. PROTECTION</h1>
        <p>
          Experience the transformative force of Aronia berry, <br />
          the legendary black chokeberry.
        </p>
          <div className="hero-buttons">
              <button className="hero-button"
                      onClick={() => window.location.href = 'https://www.armedinausa.com/'}>ORDER NOW
              </button>
              <button className="hero-button"
                      onClick={() => window.location.href = 'https://www.amazon.com/aroniatreasure'}>ALSO AVAILABLE ON
                  AMAZON
              </button>
          </div>
      </div>
        <div className="hero-image">
            <img src="/assets/image1.png" alt="Aronia berry"/>
        </div>
    </section>
  );
};

export default Hero;
