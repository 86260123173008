import React from 'react';
import '../style/services.scss';


const Services = () => {
  return (
    <section id="services" className="about">
      {/* First Part: Three boxes */}
      <div id="#services" className="about-boxes">
        <div className="about-box">
          <img src='/assets/image2.png' alt="Boost Immunity" />
          <h2>BOOST YOUR IMMUNITY</h2>
        </div>
        <div className="about-box">
          <img src='/assets/image3.png' alt="Strong Antioxidant" />
          <h2>STRONG NATURAL ANTIOXIDANT</h2>
        </div>
        <div className="about-box">
          <div className="about-box-left">
            <img src='/assets/image4.png' alt="1 SHOT 20.600 ORAC VALUES" />
          </div>
          <div className="about-box-right">
            <h2>1 SHOT 20.600 ORAC VALUE</h2>
          </div>
        </div>
      </div>

      {/* Second Part: Heading, Text and Button */}
      <div className="about-details">
        <div className="about-content">
          <div className="about-image">
            <img src='/assets/image5.png' alt="Revitalize Your Journey" />
          </div>
          <div className="about-text">
            <h1>Revitalize Your Journey</h1>
            <p>
              Elevate your wellness journey with our exceptional Aronia Treasure juice, meticulously crafted to
              invigorate your body and mind, ensuring each sip is a step towards optimal health.
            </p>
            <button className="about-button" onClick={() => window.location.href = 'https://www.armedinausa.com/'}>ORDER
              NOW
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
